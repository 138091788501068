// Custom css + overrides
.body {
  padding-top:60px;
}

.navbar-inverse .navbar-brand {
  padding-top: 12px;
  font-size:24px;
}

.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-nav > li > a {
  color: #99cc66;
  text-shadow: 10px 10px 10px #000;
}

.navbar-inverse .navbar-brand .glyphicon-shopping-cart {
  text-shadow: none;
}

.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-nav > li > a:hover {
  color: #72a635;
}

.navbar-inverse .navbar-nav > li > a {
  font-size: 18px;
}

.panel-default > .panel-heading {
  color: #1393c8;
}

.dropdown:hover .dropdown-menu {
  display:block;
}

.already-purchased td,
.already-purchased td a {
  text-decoration: line-through;
  background-color: $brand-warning;
}

.select2-container,
.select2-container .select2-selection--multiple {
  width:100% !important;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #ccc;
  box-shadow:0 1px 1px rgba(0, 0, 0, 0.075) inset;
  transition:border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
